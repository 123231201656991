<template>
  <div class="invoice_check">
    <div class="query_condition">
      <div class="query_item">
        <span class="query_item_title">开票日期</span>
        <el-date-picker
            v-model="invoiceTime"
            type="daterange"
            align="left"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            class="datePicker"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="pickerOptions">
        </el-date-picker>
      </div>
      <div class="query_item">
        <span class="query_item_title">发票代码</span>
        <el-input placeholder="" v-model="invoiceCode" maxlength="25"  @keyup.enter.native="searchList">
          <i class="iconfont iconicon1-30" slot="suffix" @click="searchList"></i>
        </el-input>
      </div>
      <div class="query_item">
        <span class="query_item_title">发票号码</span>
        <el-input placeholder="" v-model="invoiceNo" maxlength="25"  @keyup.enter.native="searchList">
          <i class="iconfont iconicon1-30" slot="suffix" @click="searchList"></i>
        </el-input>
      </div>
      <div class="query_item">
        <span class="query_item_title">报销人</span>
        <el-input placeholder="" v-model="reimburseUser" maxlength="25"  @keyup.enter.native="searchList">
          <i class="iconfont iconicon1-30" slot="suffix" @click="searchList"></i>
        </el-input>
      </div>
      <div class="query_item">
        <span class="query_item_title">报销日期</span>
        <el-date-picker
            v-model="reimburseTime"
            type="daterange"
            align="left"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            class="datePicker"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="pickerOptions">
        </el-date-picker>
      </div>
      <div class="query_item">
        <el-button type="primary" @click="searchList" style="margin-right: 20px;" v-auth='"reimbursementCheck:lookup"'>查询</el-button>
        <el-button class="el-button el-button--primary el-button--medium is-plain" @click="reset" v-auth='"reimbursementCheck:lookup"'>重置</el-button>
        </div>
    </div>

    <div class="table">
      <div class="btn_area">
        <el-button type="primary" @click="imports" style="margin-right: 20px;" icon="iconfont iconicon-03 rei_check_icon" v-auth='"reimbursementCheck:import"'>报销记录导入</el-button>
        <el-button type="primary" @click="showExportDialog" style="margin-right: 20px;" icon="iconfont iconicon-04 rei_check_icon" v-auth='"reimbursementCheck:export"'>报销记录导出</el-button>
        <el-button type="primary" @click="showDupcheckDialog" style="margin-right: 20px;" icon="iconfont iconicon-16 rei_check_icon" v-auth='"reimbursementCheck:lookup"'>报销查重</el-button>
        <el-button class="el-button el-button--primary el-button--medium is-plain" @click="doDelete" style="margin-right: 20px;" icon="el-icon-delete" v-auth='"reimbursementCheck:delete"'>删除</el-button>
      </div>
      <div class="filter-container">
        <books-table  :userId='uId'
                      :invoiceTime='invoiceTime'
                      :reimburseTime='reimburseTime'
                      :invoiceCode='invoiceCode'
                      :invoiceNo='invoiceNo'
                      :reimburseUser='reimburseUser'
                      :startQuery="startQuery"
                      ref="booksTable"
                      :expand="false"
                      @selectionList='selection'
                      @current-events="handleCurrentChange"/>
      </div>
    </div>
    <export-dialog :visible='exportVis'  @selectType="selectType"  :isdeling='isdeling' :confirmDialog='confirmExport' :cancelDialog='exportCancel' ref="export"/>
    <import-dialog :visible='importVis' :cancelDialog='importCancel'/>
    <dupcheck-dialog :visible='dupCheckVis' @cancelDialog='dupcheckCancel' ref="dupcheck"/>
  </div>

</template>

<script>
  import BooksTable from './modules/booksTable'
  import exportDialog from './modules/dialog/export'
  import importDialog from './modules/dialog/imports'
  import dupcheckDialog from './modules/dialog/dupCheck'
  import axios from "axios";
  export default {
    components: { BooksTable,exportDialog,importDialog,dupcheckDialog},
    data() {
      return {
        //每页记录条数
        pageSize: 10,
        //当前页码
        pageNum: 1,
        // 总数量
        total: 0,
        userId: this.getToken('userId'),
        userName: this.getToken('userName'),
        userList: [],  //用户列表
        invoiceTime: [],
        reimburseTime: [],
        invoiceCode:'',
        invoiceNo:'',
        reimburseUser:'',
        startQuery:0,
        uId: '',
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }],
          onPick: ({ maxDate, minDate }) => {
            this.choiceDate = minDate.getTime()
            if (maxDate) {
              this.choiceDate = ''
            }
          },
          disabledDate: (time) => {

          }
        },
        exportVis:false,
        importVis: false,
        multipleSelection:[],
        exportType:'2',
        isdeling: false,
        dupCheckVis:false
      }
    },
    methods: {
      dupcheckCancel(){
        this.$refs.dupcheck.ruleForm={}
        this.dupCheckVis=false;
      },
      selectType(value){
        this.exportType = value
      },
      selection(val){
        console.log(val)
        this.multipleSelection = val.map(e=>{return e.id});
      },

      dupCheckBatchDelete(){
        this.$store.dispatch("dupCheckBatchDelete",{
          ids: ["111"]
        }).then(res => {
          if (res.success) {
            einvAlert.success("提示",'删除成功')
            this.delDialog = false
            this.getList()
          } else {
            einvAlert.error("提示",res.msg)
          }
          this.isdeling = false
        }).catch(err => {
          this.isdeling = false
        //  einvAlert.error("提示",err)
        })

      },
      doDelete(){
        if(this.multipleSelection.length==0){
          einvAlert.warning("提示","请至少勾选一条记录");
          return
        }
        let  _this=this;
          einvAlert.confirmHandHidden(function(){
            _this.$store.dispatch("dupCheckBatchDelete",{
              ids: _this.multipleSelection
            }).then(res => {
              if (res.success) {
                einvAlert.hidden();
                einvAlert.success("提示","删除成功")
                _this.$refs.booksTable.getList();
              } else {
                einvAlert.error("提示","删除失败")
              }
            }).catch(err => {
            })


          },"确认删除吗？",'确认','取消')
      },
      initTableList(){
        this.$refs.booksTable.getList();
      },
      reset(){
        this.invoiceTime=[];
        this.reimburseTime=[];
        this.invoiceCode='';
        this.invoiceNo='';
        this.reimburseUser='';
        this.startQuery++
      },
      imports(){
        this.importVis = true;
      },
      importCancel(){
        this.importVis = false;
      },
      exportCancel(){
        this.exportVis=false;
      },
      showDupcheckDialog(){
        this.dupCheckVis=true
      },
      confirmExport(){
        if(this.$refs.booksTable.total === 0){
          einvAlert.warning('提示','当前查询条件无数据，请检查',true,'确定')
          return;
        }

        if(this.exportType === '1' && this.multipleSelection.length === 0){
          einvAlert.warning('提示','请先勾选',true,'确定')
          this.isdeling = false
          this.exportVis = false
          return
        }
        var ids =''
        this.multipleSelection.forEach(e=>{
          ids += e+','
        })
        ids = ids.slice(0,ids.lastIndexOf(','))
        axios({
          method: 'get',
          url: '/api/einvoice/reimburse/batchExport',
          responseType: 'blob',
          params: {
            companyId: this.getToken('companyId'),
            ids: this.exportType === '1'?ids:'',
            exportType: this.exportType,
            ...this.$refs.booksTable.exportForm
          },
          headers: {
            'Content-Type': "application/json;charset=utf-8",
            'Authorization': this.getCookies('Authorization'),
            'group' :process.env.VUE_APP_GROUP
          }
        }).then((res) => {
          let data = res.data
          let url = window.URL.createObjectURL(new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url;
          var now = window.formatDate(new Date(), 'yyyyMMddhhmmss')
          link.setAttribute('download', '报销记录' + now + '.xls')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          this.isdeling = false
          this.exportVis = false
        }).catch(err=>{
          this.isdeling = false
        //  einvAlert.error("提示",err)
        })
        this.exportVis=false;
      },
      showExportDialog(){
        this.exportVis=true;
      },
      selectUserId(userId) {
        this.userId = userId
      },
      searchList() {
        this.$refs.booksTable.getList();
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.pageNum = val;
        this.getList();
      },
      toPath() {
        this.$router.push('/install/voucherGroup/add')
      },
      listUser() {
        this.$store.dispatch("listUser",{companyId: this.getToken('companyId'), index: 1,rows:100}).then(res => {
          if (res.success) {
            this.userList = res.data;
            if(this.userList){
              this.uId=this.userList[0].userId;
              let _this=this;
              setTimeout(function(){
                _this.searchList(); //实例列表
              },100)
            }
          } else {
            einvAlert.error("提示",res.msg)
          }
        }).catch(err => {
        })
      }
    },
    mounted() {
      this.listUser(); //用户列表
    },
  }
</script>
<style lang='less'>
.invoice_check{
 .rei_check_icon {
  font-size: 12px !important;
   margin-right: 8px;
  }
  .el-dialog__header{
    background-color: #409EFF;
    color: #ffffff;
    padding-bottom: 20px;
    font-size: 20px;
  }
}





</style>
<style lang='less' scoped>
 .invoice_check{
   width: 100%;
   .query_condition{
      width: 100%;
      padding: 1%;
      background: #fff;
      margin-bottom: 1%;
      .query_item{
        display: inline-block;
        margin: 10px 60px 10px 0px;
        .query_item_title{
          font-size: 16px;
          color: #333333;
          margin-right: 20px;
        }
        .el-date-editor--daterange{
          width: 400px !important;
        }
        .el-input{
          display: inline-block;
          width: 200px;
        }
      }
    }
   .table{
     width: 100%;
     padding: 1% 1% 2% 1%;
     background: #fff;
   }

 }

 .filter-container {
    padding: 1.04rem 0;
    height: calc(100% - 90px);
  }
</style>
